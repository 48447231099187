import Vue from "vue";
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from "vuetify/lib/framework";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

Vue.component("font-awesome-icon", FontAwesomeIcon); // 全局注册组件
library.add(fas); // 包含需要的图标

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "faSvg",
  },
  theme: {
    themes: {
      light: {
        primary: "#002442",
        secondary: "#487AA4",
        accent: "#8c9eff",
        error: "#E2252F",
        anchor: "#E2252F",
      },
    },
  },
});
