import request from "@/utils/request";

export function getLeagueList() {
  return request({
    url: "/league_list/",
    method: "get",
  });
}

export function getSeasonSegmentTree(params) {
  return request({
    url: "/season_segment_tree/",
    method: "get",
    params,
  });
}

export function getFavoriteLeagueData() {
  return request({
    url: "/favorite_league_data/",
    method: "get",
  });
}

export function getLeagueOverview(params) {
  return request({
    url: "/league_overview/",
    method: "get",
    params,
  });
}

export function getTeamOverview(params) {
  return request({
    url: "/team_overview/",
    method: "get",
    params,
  });
}

export function getPlayerOverview(params) {
  return request({
    url: "/player_overview/",
    method: "get",
    params,
  });
}

export function getTeam(params) {
  return request({
    url: "/team/",
    method: "get",
    params,
  });
}

export function getTeamRecords(params) {
  return request({
    url: "/team_records/",
    method: "get",
    params,
  });
}

export function getTeamStatistics(params) {
  return request({
    url: "/team_statistics/",
    method: "get",
    params,
  });
}

export function getTeamRoster(params) {
  return request({
    url: "/team_roster/",
    method: "get",
    params,
  });
}

export function getPlayer(params) {
  return request({
    url: "/player/",
    method: "get",
    params,
  });
}

export function getPlayerGameLog(params) {
  return request({
    url: "/player_game_log/",
    method: "get",
    params,
  });
}

export function getPlayerStatistics(params) {
  return request({
    url: "/player_statistics/",
    method: "get",
    params,
  });
}
