<template lang="pug">
.nav
  .root.position-fixed.w-100(
    :height="$store.state.navbarHeight",
    style="z-index: 3"
  )
    .pa-20.px-md-20
      v-app-bar.navbar.align-center(
        :elevation="0",
        :height="$store.state.navbarHeight",
        dense,
        color="#002442"
      )
        //- Mobile version
        .right_icons.d-flex.align-center.d-md-none
          v-app-bar-nav-icon.ml-3(@click="drawer = true", color="white")
          v-toolbar-title.d-flex.justify-center.flex-grow-1.align-center(
            @click="toHome"
          )
            svg-icon(
              iconClass="gc_logo" 
              className="gc_logo"
              style="width: 120px; height: 80px"
            )
        .icons.d-flex.align-center.d-none.d-md-block(v-if="hasFantasy")
          svg-icon.fantasy_logo_border.d-block.d-md-none.mr-3(
            iconClass="f_logo",
            @click="toFantasyPage"
          )
          v-btn.d-md-none(icon="", small, v-if="false")
            v-icon(small, color="white") mdi-bell
          v-btn.d-md-none.mr-2(
            v-if="!$store.state.user.accessToken",
            @click="toLogin",
            icon="",
            small
          )
            v-icon(small, color="white") mdi-account
          .user_symbol.d-flex.d-md-none(
            v-if="$store.state.user.accessToken && !$store.state.user.photo",
            @click="toLogin"
          )
            img.user_avater.avater_sm(:src="gcAvatar")
          .user_symbol.d-flex.d-md-none(
            v-if="$store.state.user.accessToken && $store.state.user.photo",
            @click="toLogin"
          )
            img.user_avater.avater_sm(:src="$store.state.user.photo")

        //- Desktop version
        .d-none.d-md-block(style="width: 100%; padding: 0 5%")
          div(style="width: 40%")
            v-tabs(
              dark,
              background-color="#002442",
              color="white",
              v-model="tabSelect",
              optional,
              show-arrows
            )
              v-tabs-slider(color="#fff")
              .left_menu.d-flex
                v-menu(
                  offset-y,
                  nudge-top="3",
                  open-on-hover,
                  v-for="(league, index) in leagueList",
                  :key="league.id"
                )
                  template(v-slot:activator="{ on, attrs }")
                    v-tab(
                      style="color: #fff;"
                      @click="toLeagueOverview(index)",
                      :ripple="false",
                      v-bind="attrs",
                      v-on="on"
                    ) {{ league.name }}
                  .sub_menu
                    .sub_menu_container
                      li.item(
                        :class="{ selected: $route.name == 'LeagueLeagueOverview' && $route.params.leagueId == league.id }",
                        @click="toLeagueOverview(index)"
                      ) 首頁
                      li.item(
                        :class="{ selected: $route.name == 'LeagueTeamOverview' && $route.params.leagueId == league.id }",
                        @click="toTeamOverview(index)"
                      ) 球隊
                      li.item(
                        :class="{ selected: $route.name == 'LeaguePlayerOverview' && $route.params.leagueId == league.id }",
                        @click="toPlayerOverview(index)"
                      ) 球員
          v-toolbar-title.d-flex.justify-center.align-center(@click="toHome")
            svg-icon(
              iconClass="gc_logo",
              className="gc_logo",
              style="width: 120px; height: 80px"
            )
          .left_menu.d-flex.align-center(v-if="hasFantasy")
            svg-icon.fantasy_logo_border.mr-10(
              iconClass="f_logo",
              @click="toFantasyPage"
            )
            v-btn(icon="", small, v-if="false")
              v-icon(small, color="white") mdi-bell
            v-btn(
              v-if="!$store.state.user.accessToken",
              @click="toLogin",
              icon="",
              small
            )
              v-icon(small, color="white") mdi-account
            .user_symbol.d-flex(
              v-if="$store.state.user.accessToken && !$store.state.user.photo",
              @click="toLogin"
            )
              img.user_avater.avater_sm(:src="gcAvatar")
            .user_symbol.d-flex(
              v-if="$store.state.user.accessToken && $store.state.user.photo",
              @click="toLogin"
            )
              img.user_avater.avater_sm(:src="$store.state.user.photo")
  v-navigation-drawer(v-model="drawer", fixed, temporary)
    v-expansion-panels(accordion)
      v-expansion-panel(
        :class="{ selected: tabSelect == index }",
        v-for="(league, index) in leagueList",
        :key="league.id"
      )
        v-expansion-panel-header
          | {{ league.name }}
          template(v-slot:actions="")
            v-icon(color="#ababab")
              | mdi-chevron-down
        v-expansion-panel-content
          .sub_menu_container
            li.item(
              :class="{ selected: tabSelect == index && $route.name == 'LeagueLeagueOverview' && $route.params.leagueId == league.id }",
              @click="toLeagueOverview(index)"
            ) 首頁
            li.item(
              :class="{ selected: tabSelect == index && $route.name == 'LeagueTeamOverview' && $route.params.leagueId == league.id }",
              @click="toTeamOverview(index)"
            ) 球隊
            li.item(
              :class="{ selected: tabSelect == index && $route.name == 'LeaguePlayerOverview' && $route.params.leagueId == league.id }",
              @click="toPlayerOverview(index)"
            ) 球員
</template>

<script>
import { getLeagueList } from "@/api/league";
import fLogoBorder from "@/assets/img/fantasy/f_logo_border.png";
import gcAvatar from "@/assets/img/gc-avatar.png";

export default {
  name: "Navbar",

  data() {
    return {
      gcAvatar,
      fLogoBorderImg: fLogoBorder,
      hoveredTabIndex: null,
      isLeagueMenuShow: false,
      isPleagueMenuShow: false,
      group: 0,
      drawer: false,
      oldTabSelect: -1,
      tabSelect: -1,
      rightTablist: [],
      leagueList: [],
      hasFantasy: false,
    };
  },
  watch: {
    tabSelect() {
      if (this.tabSelect == undefined) {
        this.$nextTick(() => {
          this.tabSelect = this.oldTabSelect;
        });
      } else {
        this.oldTabSelect = this.tabSelect;
      }
    },
  },
  created() {
    this.getLeagueList();
  },
  methods: {
    async getLeagueList() {
      const response = await getLeagueList();
      this.leagueList = response.data;
    },
    toLogin() {
      if (!this.$store.state.user.accessToken) {
        this.$router.push({ name: "Login" });
      } else {
        this.$router.push({ name: "MemberCenter" });
      }
    },
    toPage(index) {
      const league_name = this.leagueList[index].name;
      this.$router.push({ name: "League", params: { league: league_name } });
    },
    toHome() {
      this.$router.push({ name: "Home" });
      this.tabSelect = -1;
    },
    toFantasyPage() {
      this.$router.push({ name: "Fantasy" });
    },
    toLeagueOverview(index) {
      const leagueId = this.leagueList[index].id;
      if (this.tabSelect !== index) this.tabSelect = index;

      this.$router.push({
        name: "LeagueLeagueOverview",
        params: { leagueId: leagueId },
      });
    },
    toTeamOverview(index) {
      const leagueId = this.leagueList[index].id;
      if (this.tabSelect !== index) this.tabSelect = index;

      this.$router.push({
        name: "LeagueTeamOverview",
        params: { leagueId: leagueId },
      });
    },
    toPlayerOverview(index) {
      const leagueId = this.leagueList[index].id;
      if (this.tabSelect != index) this.tabSelect = index;

      this.$router.push({
        name: "LeaguePlayerOverview",
        params: { leagueId: leagueId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/navbar";
</style>
