export default [
  {
    path: "/league/:leagueId",
    name: "League",
    component: () => import("@/views/League/index.vue"),
    redirect: {
      name: "LeagueLeagueOverview",
    },
    children: [
      {
        path: "/league/:leagueId/leagueoverview",
        name: "LeagueLeagueOverview",
        component: () => import("@/views/League/LeagueOverview/index"),
      },
      {
        path: "/league/:leagueId/teamoverview",
        name: "LeagueTeamOverview",
        component: () => import("@/views/League/TeamOverview/index"),
      },
      {
        path: "/league/:leagueId/playeroverview",
        name: "LeaguePlayerOverview",
        component: () => import("@/views/League/PlayerOverview/index"),
      },
    ],
  },
  {
    path: "/league/:leagueId/team/:teamId/season-segment/:seasonSegmentId/",
    name: "LeagueTeam",
    component: () => import("@/views/League/Team/index.vue"),
    redirect: {
      name: "LeagueTeamRecords",
    },
    children: [
      {
        path:
          "/league/:leagueId/team/:teamId/season-segment/:seasonSegmentId/records",
        name: "LeagueTeamRecords",
        component: () => import("@/views/League/Team/Records/index"),
      },
      {
        path:
          "/league/:leagueId/team/:teamId/season-segment/:seasonSegmentId/statistics",
        name: "LeagueTeamStatistics",
        component: () => import("@/views/League/Team/Statistics/index"),
      },
      // {
      //   path:
      //     "/league/:leagueId/team/:teamId/season-segment/:seasonSegmentId/advancedstatistics",
      //   name: "LeagueTeamAdvancedStatistics",
      //   component: () => import("@/views/League/Team/AdvancedStatistics/index"),
      // },
      {
        path:
          "/league/:leagueId/team/:teamId/season-segment/:seasonSegmentId/roster",
        name: "LeagueTeamRoster",
        component: () => import("@/views/League/Team/Roster/index"),
      },
    ],
  },
  {
    path: "/league/:leagueId/player/:playerId",
    name: "LeaguePlayer",
    component: () => import("@/views/League/Player/index.vue"),
    redirect: {
      name: "LeaguePlayerGameLog",
    },
    children: [
      {
        path:
          "/league/:leagueId/player/:playerId/season-segment/:seasonSegmentId/game-log",
        name: "LeaguePlayerGameLog",
        component: () => import("@/views/League/Player/GameLog/index"),
      },
      {
        path:
          "/league/:leagueId/player/:playerId/season-segment/:seasonSegmentId/statistics",
        name: "LeaguePlayerStatistics",
        component: () => import("@/views/League/Player/Statistics/index"),
      },
      // {
      //   path: "/:leagueId/player/:playerId/job",
      //   name: "LeaguePlayerJob",
      //   component: () => import("@/views/League/Player/Job/index"),
      // },
      // {
      //   path: "/:leagueId/player/:playerId/advanced",
      //   name: "LeaguePlayerAdvanced",
      //   component: () => import("@/views/League/Player/Advanced/index"),
      // },
      // {
      //   path: "/:leagueId/player/:playerId/teameffect",
      //   name: "LeaguePlayerTeamEffect",
      //   component: () => import("@/views/League/Player/TeamEffect/index"),
      // },
    ],
  },
  {
    path: "",
    name: "LeagueGame",
    component: () => import("@/views/League/Game/index.vue"),
    redirect: {
      name: "LeagueGameBoxScore",
    },
    children: [
      {
        path: "league/:leagueId/season-segment/:seasonSegmentId/game/:id",
        name: "LeagueGameBoxScore",
        component: () => import("@/views/League/Game/BoxScore/index"),
      },
      {
        path: "/:leagueId/game/:id/advancedstatistics",
        name: "LeagueGameAdvancedStatistics",
        component: () => import("@/views/League/Game/AdvancedStatistics/index"),
      },
    ],
  },
];
